import $ from './styles/index.js'
import { toVk } from '@utils/router'
import icoVk from './images/vk.svg'


const VkFooterBtn = () => (
	<a className={$.container} target="_blank" href={toVk()}>
		<img src={icoVk}/>
	</a>
)

export default VkFooterBtn
