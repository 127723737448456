import { get, flow } from 'lodash'
import { call, put, select } from 'redux-saga/effects'

import feofan from '@libs/feofan'
import info from '@utils/info'
import { getOfferResolvedWithStatus } from '@store/state/appState/actions'
import { channelSelector } from '@store/state/appState/selectors'
import { getSearchQuery } from '@store/state/domainData/selectors'
import { getQueryFromString } from '@utils/common/searchQuery'
import {
	OPERATION_STATUSES,
	EXTENDED_SERVICES,
} from '@libs/foma/types'

import { makeCancellable, withError, getFrontUrl } from '../utils'


export const cancellableCreateOffer = makeCancellable(feofan.createOffer)
export const cancellableGetOffer = makeCancellable(feofan.getOffer)
export const cancellableRebuildOffer = makeCancellable(feofan.rebuildOffer)
export const cancellableGetExtendedOffer = makeCancellable(feofan.getExtendedOffer)

export const extendedServices = [
	{ type: EXTENDED_SERVICES.SMS },
	{ type: EXTENDED_SERVICES.INSURANCE },
	{ type: EXTENDED_SERVICES.CHECK_IN },
	{ type: EXTENDED_SERVICES.PRIORITY_SUPPORT },
]

export const getExcludedExtServices = (data) => {
	const services = data.services || []

	return extendedServices.filter(({ type: serviceType }) => (
		!services.some(({ type }) => (type === serviceType))
	))
}

const hasExtendedServices = flow(
	getExcludedExtServices,
	(services) => (services.length === 0)
)

const getConsistObjFromRawResponse = flow(
	getSearchQuery,
	getQueryFromString,
	({ adults, children, infants }) => ({ adults, children, infants })
)

function clearInitOfferId (channel) {
	if (!/\w+:\w+/.test(channel)) return

	const key = `tio:${channel}`
	sessionStorage.removeItem(key)
}

function rememberInitOfferId (channel, resData) {
	const getCookie = (name) => {
		try {
			const cookieString = document.cookie
			const cookies = cookieString.split('; ')
			for (let cookie of cookies) {
				const [ cookieName, cookieValue ] = cookie.split('=', 2)
				if (cookieName === name) {
					return cookieValue
				}
			}
			return null
		}
		catch (_err) {
			return null
		}
	}

	const setCookie = (name, value, ttlMs) => {
		const date = new Date()
		date.setTime(date.getTime() + ttlMs)
		const expires = `expires=${date.toUTCString()}`
		const path = 'path=/'
		document.cookie = `${name}=${value}; ${expires}; ${path}; Secure; SameSite=Strict`
	}

	try {
		if (!/\w+:\w+/.test(channel)) return

		const key = `tio:${channel}`
		const cookieCheckKey = `${key}:set`
		if (getCookie(cookieCheckKey)) return

		const cTime = new Date().getTime()
		sessionStorage.setItem(key, `${cTime}:${resData.data.offers[0].id}`)
		setCookie(cookieCheckKey, '1', 30 * 86400 * 1000)
	}
	catch (err) {
		info('cant remember init offer id', err)
	}
}

export const getOptionsForExtOffer = (rawData, optsGetOffer, serviceType, subType) => {
	// TODO: make a better solution
	const offerId = get(rawData, 'data.offers.0.id', '')
	const consistObj = getConsistObjFromRawResponse(rawData)

	return {
		...optsGetOffer,
		id: offerId,
		serviceType,
		subType,
		consist: consistObj,
	}
}


export function* fetchExtOffer (optsGetOffer, evtChannel, resData, service) {
	const { type: serviceType, subType } = service
	const optsGetExtOffer = yield call(getOptionsForExtOffer, resData, optsGetOffer, serviceType, subType)

	try {
		return yield call(cancellableGetExtendedOffer, optsGetExtOffer)
	}
	catch (e) {
		return resData
	}
}

export function* getExtOffersWithAllServices (resData, optsGetOffer, evtChannel) {
	const excludedExtServices = yield call(getExcludedExtServices, resData.data)

	let acc = resData

	for (let i = 0; i < excludedExtServices.length; i++) {
		const extService = excludedExtServices[i]

		acc = yield call(fetchExtOffer, optsGetOffer, evtChannel, acc, extService)
	}

	return acc
}

export default function* handleLoadOffer (evtChannel, meta) {

	const { offerId, optsCreateOffer } = meta
	const shouldCreateOffer = Boolean(optsCreateOffer)

	const frontUrl = yield call(getFrontUrl)
	const channel = yield select(channelSelector)

	const optsFetchOffer = {
		id: offerId,
		frontUrl,
		channel,
		...optsCreateOffer,
	}

	let resData = null
	let errData = null
	let needRebuild = false

	try {
		const offerFetcher = shouldCreateOffer ? cancellableCreateOffer : cancellableGetOffer
		resData = yield call(offerFetcher, optsFetchOffer)
		if (shouldCreateOffer) {
			clearInitOfferId(channel)
		}
		else {
			rememberInitOfferId(channel, resData)
		}
		yield put(getOfferResolvedWithStatus(get(resData, 'status')))
	}
	catch (err) {
		const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
		yield put(getOfferResolvedWithStatus(status))
		// do not rebuild if offer was not created
		if (status === OPERATION_STATUSES.UNAVAIL && !shouldCreateOffer) {
			needRebuild = true
		}
		else {
			errData = { ...err.data, status }
			yield put(evtChannel, withError(errData))
			return status
		}
	}

	const newOfferId = get(resData, 'data.offers.0.id', offerId)
	const optsGetOffer = {
		id: newOfferId,
		frontUrl,
		channel,
	}

	if (needRebuild) {
		try {
			resData = yield call(cancellableRebuildOffer, optsGetOffer)
		}
		catch (err) {
			const status = get(err, 'data.status', OPERATION_STATUSES.ERROR)
			errData = { ...err.data, status }
			const hasSearchInfo = Boolean(get(err, 'data.meta.search_info', null))
			const evtPayload = hasSearchInfo ? errData : withError(errData)
			yield put(evtChannel, evtPayload)
			return status
		}
	}

	if (resData && !hasExtendedServices(resData.data)) {
		// if offer has no extended services fares
		// need to load them by getExtendOffer API
		try {
			resData = yield call(getExtOffersWithAllServices, resData, optsGetOffer, evtChannel)
		}
		catch (err) {
			// do nothing
			// if can't load extended offer return ordinary offer
			info('can`t load extended offer', err)
		}
	}

	yield put(evtChannel, resData)

	return resData.status
}
