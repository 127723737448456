import { values, flatten, mapValues } from 'lodash'
import { createSelector } from 'reselect'
import { getDate } from '@utils/time-utils'
import { EXTENDED_SERVICES } from '@libs/foma/types'
import {
	offerByIdSelector,
	productByIdSelector,
	getFares,
	getServices,
	getActivities,
	getSegments,
	flightsProductsSelector,
	getOffers,
} from './core.js'
import {
	getFaresFromOffer,
	getFaresFromProduct,
	getServiceFromFare,
	getActivitiesFromService,
	getSegmentsFromActivity,
} from './itemsRelation.js'


const byTime = ({ arrive }, { depart }) => (getDate(arrive.time) - getDate(depart.time))
export const getSortedSegmentsFromActivities = (segments, activities) => flatten(
	activities.map((activity) => values(getSegmentsFromActivity(segments, activity)))
).sort(byTime)

const makeServicesSelector = (rootItemGetter, faresGetter) => {
	const faresSelector = createSelector(
		[ getFares, rootItemGetter ],
		(fares, rootItem) => values(faresGetter(fares, rootItem))
	)
	return createSelector(
		[ getServices, faresSelector ],
		(services, fares) => fares
			.map((fare) => getServiceFromFare(services, fare))
			.filter(({ type }) => (type === EXTENDED_SERVICES.TRANSFER))
	)
}

const makeSegmentsSelector = (rootItemGetter, faresGetter) => {
	const faresSelector = createSelector(
		[ getFares, rootItemGetter ],
		(fares, rootItem) => values(faresGetter(fares, rootItem))
	)
	const flightServicesSelector = createSelector(
		[ getServices, faresSelector ],
		(services, fares) => fares
			.map((fare) => getServiceFromFare(services, fare))
			.filter(({ type }) => (type === EXTENDED_SERVICES.TRANSFER))
	)
	const activitiesSelector = createSelector(
		[ getActivities, flightServicesSelector ],
		(activities, services) => flatten(
			services.map((service) => values(getActivitiesFromService(activities, service)))
		)
	)
	return createSelector(
		[ getSegments, activitiesSelector ],
		getSortedSegmentsFromActivities
	)
}

export const sortedSegmentsFromOfferSelector = makeSegmentsSelector(offerByIdSelector, getFaresFromOffer)
export const sortedServicesFromOfferSelector = makeServicesSelector(offerByIdSelector, getFaresFromOffer)
export const sortedSegmentsFromProductSelector = makeSegmentsSelector(productByIdSelector, getFaresFromProduct)

export const makeSortedSegmentsFromProductSelector = () => (
	makeSegmentsSelector(productByIdSelector, getFaresFromProduct)
)

export const makeSortedSegmentsByRootItemIdSelector = (rootItemsGetter, faresGetter) => {
	const faresByRootItemIdSelector = createSelector(
		[ getFares, rootItemsGetter ],
		(fares, rootItems) => mapValues(rootItems, (item) => (
			values(faresGetter(fares, item))
		))
	)
	const flightServicesByRootItemIdSelector = createSelector(
		[ getServices, faresByRootItemIdSelector ],
		(services, faresByRootItemId) => mapValues(faresByRootItemId, (fares) => fares
			.map((fare) => getServiceFromFare(services, fare))
			.filter(({ type }) => (type === EXTENDED_SERVICES.TRANSFER))
		)
	)
	const activitiesByRootItemIdSelector = createSelector(
		[ getActivities, flightServicesByRootItemIdSelector ],
		(activities, servicesByRootItemId) => mapValues(servicesByRootItemId, (services) => (
			flatten(services.map((service) => values(getActivitiesFromService(activities, service))))
		))
	)
	return createSelector(
		[ getSegments, activitiesByRootItemIdSelector ],
		(segments, activitiesByRootItemId) => mapValues(activitiesByRootItemId, (activities) => (
			getSortedSegmentsFromActivities(segments, activities)
		))
	)
}

export const sortedSegmentsByProductIdSelector = makeSortedSegmentsByRootItemIdSelector(
	flightsProductsSelector,
	getFaresFromProduct,
)
export const sortedSegmentsByOfferIdSelector = makeSortedSegmentsByRootItemIdSelector(
	getOffers,
	getFaresFromOffer,
)
